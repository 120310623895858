import { ViewContainerRef, ElementRef, Input, Directive } from '@angular/core';

import { SatPopover } from '@zerops/zef';

@Directive({
  standalone: true,
  selector: '[vshcDynamicPopAnchor]',
  exportAs: 'dynamicPopAnchor'
})
export class DynamicPopAnchorDirective {

  @Input()
  vshcDynamicPopAnchor: SatPopover;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef
  ) { }

  open(callOpen = true, anchor?: HTMLElement | ElementRef<HTMLElement>) {
    if (this.vshcDynamicPopAnchor) {
      this.vshcDynamicPopAnchor.setCustomAnchor(
        this._viewContainerRef,
        anchor ? anchor : this._elementRef
      );

      if (callOpen) {
        this.vshcDynamicPopAnchor.open();
      }
    }
  }

  anchor(anchor?: HTMLElement | ElementRef<HTMLElement>) {
    if (this.vshcDynamicPopAnchor) {
      this.vshcDynamicPopAnchor.setCustomAnchor(
        this._viewContainerRef,
        anchor ? anchor : this._elementRef
      );
    }
  }

  close() {
    if (this.vshcDynamicPopAnchor) {
      this.vshcDynamicPopAnchor.close();
    }
  }

  toggle(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.vshcDynamicPopAnchor.isOpen() ? this.close() : this.open();
  }
}
