import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';

import { ZefFormBase, ZefFormsModule } from '@zerops/zef';
import { AddAliasFormState } from './add-alias-form.model';

@Component({
    selector: 'vshcdn-add-alias-form',
    templateUrl: './add-alias-form.component.html',
    styleUrls: ['./add-alias-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatInputModule,
        ZefFormsModule
    ]
})
export class AddAliasFormComponent extends ZefFormBase<AddAliasFormState> {

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

}
