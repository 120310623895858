import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'vshc-status-icon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.scss'],
    imports: [
        NgStyle
    ]
})
export class StatusIconComponent {

  @Input()
  color: string;

  @Input()
  text: string | number;

  @Input()
  fontSize: number;

  @Input()
  size = 20;

}
