import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoService } from '@jsverse/transloco';

import {
  formValueOnValid,
  selectZefDialogState,
  zefDialogClose,
  ZefReactiveComponentBase,
  ZefProgressErrorModule
} from '@zerops/zef';
import { urlsCleanup, VshcDialogWrapperComponent } from '@vshosting/components';
import { DomainBaseActions, DomainEntity } from '@vshosting/cdn/core';
import { environment } from '@vshosting/cdn/env';
import { FEATURE_NAME } from './warm-cache-dialog.constant';
import { WarmCacheDialogForm } from './warm-cache-dialog.form';
import { WarmCacheFormComponent } from './components';

@Component({
    selector: 'vshcdn-warm-cache-dialog',
    templateUrl: './warm-cache-dialog.feature.html',
    styleUrls: ['./warm-cache-dialog.feature.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatIconModule,
        ZefProgressErrorModule,
        WarmCacheFormComponent,
        VshcDialogWrapperComponent
    ]
})
export class WarmCacheDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #translocoService = inject(TranslocoService);
  #domainEntity = inject(DomainEntity);

  // # Forms
  warmCacheForm = inject(WarmCacheDialogForm);

  // # Event Streams
  onWarmCache$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  warmCacheActionKey = DomainBaseActions.warmCache.type;

  // -- async
  dialogState$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((d) => d?.state));
  domainId$ = this.dialogState$.pipe(
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );
  domain$ = this.domainId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  translations$ = this.domain$.pipe(
    filter((d) => !!d),
    switchMap((domain) => this.#translocoService.selectTranslateObject(
      'warmCacheDialog',
      {
        title: { domain: domain.proxyDomain },
        warmCacheForm: {
          warmCacheHint: { max: environment.maxPurgeUrls }
        }
      }
    ))
  );
  formErrorTranslations$ = this.#translocoService.selectTranslateObject(
    'formErrorMessages',
    {urlsInvalidLength: { max: environment.maxWarmCacheUrls }}
  );

  // # Resolver
  state = this.$connect({
    open: this.open$,
    warmCacheFormState: this.warmCacheForm.state$,
    translations: this.translations$,
    formErrorTranslations: this.formErrorTranslations$,
    domain: this.domain$,
  });

  // # Action Streams
  #closeAction$ = this.onClose$.pipe(map(() => zefDialogClose({ key: FEATURE_NAME })));
  #warmCacheAction$ = this.onWarmCache$.pipe(
    formValueOnValid(this.warmCacheForm),
    withLatestFrom(this.domainId$),
    map(([ { urls }, id ]) => DomainBaseActions.warmCache({
      data: {
        id,
        urls: urlsCleanup(urls)
      },
      meta: {
        tag: FEATURE_NAME
      }
    }))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this.#closeAction$,
      this.#warmCacheAction$
    ]);
  }

}
