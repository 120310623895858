import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'vshc-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule]
})
export class VshcSeparatorComponent {

  @Input()
  orientation: 'horizontal' | 'vertical' = 'vertical';

  @Input()
  size: 'mini' | 'small' | 'medium' | 'large' = 'medium';

  @Input()
  spacing: 'none' | 'mini' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'humongous' | 'disabled' = 'medium';

  @Input()
  position: 'center' | 'start' | 'end' = 'center';

}
