import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ZefFormBase, ZefFormsModule } from '@zerops/zef';
import { WarmCacheFormState } from './warm-cache-form.model';

@Component({
    selector: 'vshcdn-warm-cache-form',
    templateUrl: './warm-cache-form.component.html',
    styleUrls: ['./warm-cache-form.component.scss'],
    imports: [
        NgIf,
        MatInputModule,
        MatFormFieldModule,
        ZefFormsModule,
    ]
})
export class WarmCacheFormComponent extends ZefFormBase<WarmCacheFormState> {

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formErrorTranslations: any;

}
