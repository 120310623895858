import { Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslocoService } from '@jsverse/transloco';

import {
  selectZefDialogState,
  zefDialogClose,
  ZefReactiveComponentBase
} from '@zerops/zef';
import { ZefProgressErrorModule } from '@zerops/zef';
import { VshcDialogWrapperComponent } from '@vshosting/components';
import {
  AliasEntity,
  ALIAS_BASE_FEATURE_NAME,
  DomainEntity
} from '@vshosting/cdn/core';
import { AliasesFeature } from '@vshosting/cdn/features';
import { FEATURE_NAME } from './delete-domain-dialog.constant';

@Component({
    selector: 'vshcdn-delete-domain-dialog',
    templateUrl: './delete-domain-dialog.feature.html',
    styleUrls: ['./delete-domain-dialog.feature.scss'],
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        ZefProgressErrorModule,
        VshcDialogWrapperComponent,
        AliasesFeature
    ]
})
export class DeleteDomainDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #domainEntity = inject(DomainEntity);
  #aliasEntity = inject(AliasEntity);
  #translocoService = inject(TranslocoService)

  // # Event Streams
  onClose$ = new Subject<void>();
  onDelete$ = new Subject<void>();

  // # Data
  // -- sync
  deleteActionKey = this.#domainEntity.deleteOne.type;

  // -- async
  dialogState$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((d) => d?.state));
  domainId$ = this.dialogState$.pipe(
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );
  domain$ = this.domainId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  aliases$ = this.domainId$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((id) => this.#aliasEntity.list$({ name: ALIAS_BASE_FEATURE_NAME, id }))
  );
  translations$ = this.domain$.pipe(
    filter((d) => !!d),
    switchMap((domain) => this.#translocoService.selectTranslateObject(
      'deleteDomainDialog',
      { title: { domain: domain.proxyDomain }}
    ))
  );

  // # Resolver
  state = this.$connect({
    open: this.open$,
    domainId: this.domainId$,
    aliases: this.aliases$,
    translations: this.translations$
  });

  // # Action Streams
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  #deleteAction$ = this.onDelete$.pipe(
    withLatestFrom(this.domainId$),
    map(([ _, id ]) => this.#domainEntity.deleteOne(
      id,{
          tag: FEATURE_NAME
        }
    ))
  );

  constructor() {
    super();
    this.$dispatchActions([
      this.#closeAction$,
      this.#deleteAction$
    ]);
  }

}
