import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { map, distinctUntilChanged, filter } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {
  ZefReactiveComponentBase,
  ZefSnackModule,
  zefSelectAuthState,
  ZefAuthState,
  ZefPermissionService,
  ZefPopErrorModule,
  ZefSnackErrorModule
} from '@zerops/zef';
import { AppState } from './app.model';
import { FEATURES_COMPONENTS } from './features/features.component';
import { Roles } from './app.constant';

@Component({
    selector: 'vshcdn-app',
    templateUrl: './app.feature.html',
    styleUrls: ['./app.feature.scss'],
    imports: [
        RouterOutlet,
        ZefSnackModule,
        ZefPopErrorModule,
        ZefSnackErrorModule,
        ...FEATURES_COMPONENTS
    ]
})
export class AppFeature extends ZefReactiveComponentBase {
  #permissions = inject(ZefPermissionService);
  #store = inject<Store<AppState>>(Store);

  state: {
    isAuthChecking: boolean,
    authorized: boolean
  };

  constructor() {
    super();

    // # Permissions Setup
    // base roles
    this.#permissions.define(
      Roles.Checking,
      () => this.#store.pipe(
        select(zefSelectAuthState),
        map((s) => s === ZefAuthState.Checking),
        distinctUntilChanged()
      )
    );

    this.#permissions.define(
      Roles.Authorized,
      () => this.#store.pipe(
        select(zefSelectAuthState),
        filter((s) => s !== ZefAuthState.Checking),
        map((state) => state === ZefAuthState.Authorized),
        distinctUntilChanged()
      )
    );

    this.#permissions.define(
      Roles.Unauthorized,
      () => this.#store.pipe(
        select(zefSelectAuthState),
        filter((s) => s !== ZefAuthState.Checking),
        map((state) => state === ZefAuthState.Invalid),
        distinctUntilChanged()
      )
    );

    this.state = this.$connect({
      isAuthChecking: this.#permissions.authorize({ only: [ Roles.Checking ] }),
      authorized: this.#permissions.authorize({ only: [ Roles.Authorized ] })
    });

  }
}
