import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslocoModule } from '@jsverse/transloco';

import { ZefSnackInput } from '@zerops/zef';

@Component({
    selector: 'vshcdn-snack-success',
    templateUrl: './snack-success.feature.html',
    styleUrls: ['./snack-success.feature.scss'],
    imports: [
        MatButtonModule,
        MatCardModule,
        TranslocoModule
    ]
})
export class SnackSuccessFeature {
  @Input()
  data: ZefSnackInput;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: MatSnackBarRef<any>;
}
