import { Component, inject, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslocoService, TranslocoModule } from '@jsverse/transloco';

import { ZefError, ZefReactiveComponentBase } from '@zerops/zef';

@Component({
    selector: 'vshcdn-snack-error',
    templateUrl: './snack-error.feature.html',
    styleUrls: ['./snack-error.feature.scss'],
    imports: [
        NgFor,
        NgIf,
        MatButtonModule,
        MatCardModule,
        TranslocoModule
    ]
})
export class SnackErrorFeature extends ZefReactiveComponentBase {

  // # Deps
  #translocoService = inject(TranslocoService);

  // -- async
  translations$ = this.#translocoService.selectTranslateObject('general');

  // # Resolver
  state = this.$connect({
    translations: this.translations$
  });

  @Input()
  data!: ZefError;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance!: MatSnackBarRef<any>;
}
