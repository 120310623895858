import { Component, inject } from '@angular/core';
import { map, Subject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoService } from '@jsverse/transloco';

import {
  ZefDialogModule,
  ZefPermissionService,
  ZefReactiveComponentBase,
  zefLogin,
  formValueOnValid
} from '@zerops/zef';
import { environment } from '@vshosting/cdn/env';
import { Roles } from '@vshosting/cdn/app';
import { LoginForm } from './login.form';

@Component({
    selector: 'vshcdn-login',
    templateUrl: './login.feature.html',
    styleUrls: ['./login.feature.scss'],
    imports: [
        MatIconModule,
        MatButtonModule,
        ZefDialogModule
    ]
})
export class LoginFeature extends ZefReactiveComponentBase {

  // # Deps
  #permissions = inject(ZefPermissionService);
  #loginForm = inject(LoginForm);
  #translocoService = inject(TranslocoService);

  // # Event streams
  onSubmit$ = new Subject<void>();

  // # Data
  // -- sync
  loginKey = zefLogin.type;
  authWebsiteUrl = environment.authWebsiteUrl;

  // -- async
  isCheckingAuth$ = this.#permissions.authorize({ only: [ Roles.Checking ] });
  isOpen$ = this.#permissions.authorize({ only: [ Roles.Unauthorized ] });
  translations$ = this.#translocoService.selectTranslateObject(
    'loginDialog',
    { authWebsiteButton: { authWebsiteUrl: environment.authWebsiteUrl }}
  );

  // # Resolver
  state = this.$connect({
    isCheckingAuth: this.isCheckingAuth$,
    isOpen: this.isOpen$,
    loginFormState: this.#loginForm.state$,
    translations: this.translations$
  });

  // # Action streams
  #submitAction$ = this.onSubmit$.pipe(
    formValueOnValid(this.#loginForm),
    map((data) => zefLogin(
      data,
      { type: 'pop' }
    ))
  );

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this.#submitAction$
    ]);
  }

}
