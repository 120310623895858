import { Component, inject } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import {
  ZefReactiveComponentBase,
  selectZefDialogState,
  zefDialogClose,
  ZefAvatarModule,
  ZefProgressErrorModule,
  selectZefProgressByType
} from '@zerops/zef';
import { VshcDialogWrapperComponent } from '@vshosting/components';
import {
  DomainBaseActions,
  domainBaseState,
  DomainEntity
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './access-log-dialog.constant';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';
import setHours from 'date-fns/setHours';
import isBefore from 'date-fns/isBefore';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'vshcdn-access-log-dialog',
    templateUrl: './access-log-dialog.feature.html',
    styleUrls: ['./access-log-dialog.feature.scss'],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        TranslocoLocaleModule,
        ZefProgressErrorModule,
        ZefAvatarModule,
        VshcDialogWrapperComponent,
        MatProgressSpinnerModule
    ]
})
export class AccessLogDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #translocoService = inject(TranslocoService);
  #domainEntity = inject(DomainEntity);

  // # Event streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  columnsToDisplay = [
    'date',
    'access',
    'goAccess'
  ];

  // -- async
  selectedDate$ = new Subject();
  state$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  isLoading$ = this.#store.pipe(select(selectZefProgressByType(DomainBaseActions.domainAccessLog.type)));
  open$ = this.state$.pipe(
    map((d) => d?.state),
    tap((s) => {
      if (!s) {
        this.selectedDate$.next(undefined)
      }
    })
  );
  id$ = this.state$.pipe(
    map((d) => d?.meta?.activeId),
    filter((d) => !!d)
  );
  baseDate$ = this.state$.pipe(map((d) => d?.meta?.baseDate));
  activateDate$ = combineLatest([
    this.baseDate$,
    this.selectedDate$.pipe(startWith(undefined))
  ]).pipe(
    map(([ baseDate, selectedDate ]) => selectedDate || baseDate)
  );
  nextPrevDates$ = this.activateDate$.pipe(
    map((date) => ({
      prev: subMonths(date, 1),
      next: isBefore(
        setHours(startOfMonth(new Date(date)), 12),
        setHours(startOfMonth(new Date()), 12)
      )
      ? addMonths(date, 1)
      : undefined
    }))
  );
  accessLogs$ = this.#store.pipe(select(domainBaseState.selectDomainAccessLogs));
  domain$ = this.id$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  translations$ = this.domain$.pipe(
    filter((d) => !!d),
    switchMap((domain) => this.#translocoService.selectTranslateObject(
      'accessLogDialog',
      {
        title: { domain: domain.proxyDomain }
      }
    ))
  );
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');

  // # Resolver
  state = this.$connect({
    open: this.open$,
    activateDate: this.activateDate$,
    nextPrevDates: this.nextPrevDates$,
    accessLog: this.accessLogs$,
    isLoading: this.isLoading$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$
  });

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([

      this.onClose$.pipe(
        map(() => zefDialogClose({ key: FEATURE_NAME }))
      ),

      combineLatest([
        this.activateDate$.pipe(filter((d) => !!d), distinctUntilChanged()),
        this.id$
      ]).pipe(
        map(([ date, id ]) => DomainBaseActions.domainAccessLog({
          data: { id, date }
        }))
      )

    ]);
  }

}
