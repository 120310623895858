import { Component, inject, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

import {
  formValueOnValid,
  selectZefDialogState,
  zefDialogClose,
  ZefReactiveComponentBase
} from '@zerops/zef';
import { ZefDialogModule, ZefProgressErrorModule } from '@zerops/zef';
import {
  ConfirmPopComponent,
  DynamicPopAnchorDirective,
  StatusIconComponent,
  VshcDialogWrapperComponent
} from '@vshosting/components';
import {
  AliasBaseActions,
  AliasEntity,
  ALIAS_BASE_FEATURE_NAME,
  DomainBaseActions
} from '@vshosting/cdn/core';
import { AddAliasFormComponent } from './components/add-alias-form';
import { AliasesFeatureActions } from './aliases.state';
import { FEATURE_NAME } from './aliases.constant';
import { AliasesForm } from './aliases.form';

@Component({
    selector: 'vshcdn-aliases',
    templateUrl: './aliases.feature.html',
    styleUrls: ['./aliases.feature.scss'],
    imports: [
        NgFor,
        NgIf,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslocoModule,
        ZefDialogModule,
        ZefProgressErrorModule,
        AddAliasFormComponent,
        VshcDialogWrapperComponent,
        ConfirmPopComponent,
        DynamicPopAnchorDirective,
        StatusIconComponent
    ]
})
export class AliasesFeature extends ZefReactiveComponentBase {

  // # Deps
  #aliasEntity = inject(AliasEntity);
  #store = inject(Store);
  #translocoService = inject(TranslocoService);

  // # Forms
  addAliasForm = inject(AliasesForm);

  // # Event streams
  onAddAlias$ = new Subject<void>();
  onRemoveAlias$ = new Subject<string>();
  onAliasAddRecord$ = new Subject<string>();
  onClose$ = new Subject<void>();
  onAliasDnsCheck$ = new Subject<string>();

  // # Data
  // -- sync
  addAliasActionKey = this.#aliasEntity.addOne.type;
  addAliasDialogKey = FEATURE_NAME;
  aliasDnsCheckKey = DomainBaseActions.aliasDnsCheck.type;
  removingId: string;
  aliasStatusColorMap: Record<string, string> = {
    'true': '#66BB6A',
    'false': '#FFA726'
  };

  // -- async
  id$ = new BehaviorSubject<string>(undefined);
  open$ = this.id$.pipe(
    filter((d) => !!d),
    switchMap(() => this.#store.pipe(
      select(selectZefDialogState(FEATURE_NAME)),
      map((d) => d?.state)
    ))
  );
  translations$ = this.#translocoService.selectTranslateObject('aliases');
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');
  aliases$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((id) => this.#aliasEntity.list$({ name: ALIAS_BASE_FEATURE_NAME, id }))
  );
  hasAnyInactiveAlias$ = this.aliases$.pipe(
    map((d) => d?.some((a) => !a?.cnameVerified))
  );

  // -- angular
  @Input()
  showAdd = false;

  @Input()
  showWarning = false;

  @Input()
  forceDeleteShow = false;

  @Input()
  cname: string;

  @Input()
  set id(value: string) {
    this.id$.next(value);
    this.#id = value;
  }
  get id(): string {
    return this.#id;
  }

  // -- private
  #id: string;

  // # Resolver
  state = this.$connect({
    aliases: this.aliases$,
    open: this.open$,
    addAliasFormState: this.addAliasForm.state$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$,
    hasAnyInactiveAlias: this.hasAnyInactiveAlias$
  });

  // # Action streams
  #setIdAction$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    map((id) => AliasesFeatureActions.setId({ id }))
  );
  #addAliasActions$ = this.onAddAlias$.pipe(
    formValueOnValid(this.addAliasForm),
    withLatestFrom(this.id$),
    map(([ { aliasDomain }, domainId ]) => this.#aliasEntity.addOne(
      { domainId, aliasDomain },
      { tag: FEATURE_NAME }
    ))
  );
  #aliasAddRecordAction$ = this.onAliasAddRecord$.pipe(
    map((id) => AliasBaseActions.aliasAddRecord(id))
  );
  #removeAliasActions$ = this.onRemoveAlias$.pipe(
    map((id) => this.#aliasEntity.deleteOne(id))
  );
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  #aliasDnsCheckAction$ = this.onAliasDnsCheck$.pipe(
    map((id) => DomainBaseActions.aliasDnsCheck(id))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this.#setIdAction$,
      this.#addAliasActions$,
      this.#removeAliasActions$,
      this.#closeAction$,
      this.#aliasAddRecordAction$,
      this.#aliasDnsCheckAction$
    ]);
  }

}
