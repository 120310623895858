import { Component, Input } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';

import { ZefSnackInput } from '@zerops/zef';

@Component({
    selector: 'vshcdn-snack-warning',
    templateUrl: './snack-warning.feature.html',
    styleUrls: ['./snack-warning.feature.scss'],
    imports: [
        MatButtonModule,
        TranslocoModule
    ]
})
export class SnackWarningFeature {
  @Input()
  data: ZefSnackInput;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: MatSnackBarRef<any>;
}
