import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { map, Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoService } from '@jsverse/transloco';

import {
  ZefPermissionService,
  ZefReactiveComponentBase,
  zefLogout,
  SatPopoverModule,
  ZefProgressErrorModule
} from '@zerops/zef';
import {
  AvatarComponent,
  ActiveUserInfoComponent,
  LogoComponent
} from '@vshosting/components';
import { Roles } from '@vshosting/cdn/app';
import { UserEntity } from '@vshosting/cdn/core';
import { ServiceStatusFeature } from './components/service-status';

@Component({
    selector: 'vshcdn-app-bar',
    templateUrl: './app-bar.feature.html',
    styleUrls: ['./app-bar.feature.scss'],
    imports: [
        RouterLink,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        SatPopoverModule,
        ZefProgressErrorModule,
        AvatarComponent,
        LogoComponent,
        ActiveUserInfoComponent,
        ServiceStatusFeature
    ]
})
export class AppBarFeature extends ZefReactiveComponentBase {

  // # Deps
  #permissions = inject(ZefPermissionService);
  #userEntity = inject(UserEntity);
  #translocoService = inject(TranslocoService);

  // # Event streams
  onLogout$ = new Subject<void>();

  // # Data
  // -- sync
  logoutKey = zefLogout.type;
  logoFull = false;

  // -- async
  authorized$ = this.#permissions.authorize({ only: [ Roles.Authorized ] })
  clientUser$ = this.#userEntity.activeClientUser$;
  translations$ = this.#translocoService.selectTranslateObject('general');

  // # Resolver
  state = this.$connect({
    authorized: this.authorized$,
    clientUser: this.clientUser$,
    translations: this.translations$
  });

  // # Action streams
  #logoutAction$ = this.onLogout$.pipe(
    map(() => zefLogout())
  );

  constructor() {
    super();

    setTimeout(() => {
      this.logoFull = true;
    }, 200);

    // # Dispatcher
    this.$dispatchActions([
      this.#logoutAction$
    ]);
  }

}
