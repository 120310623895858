<button
  mat-button
  [satPopoverAnchor]="popRef"
  class="__button"
  (click)="popRef.open()"
  *ngIf="!!state.clientServiceData">

  <div class="__grid">

    <div>
      <div class="__progress-value-wrap">
        <span class="__trigger-title">{{ state.translations?.domains }}</span>

        <mat-progress-bar
          class="__progress"
          [color]="'accent'"
          [mode]="'determinate'"
          [value]="(state.clientServiceData?.domainUsed / state.clientServiceData?.domainLimit) * 100">
        </mat-progress-bar>

        <span class="__trigger-value"><strong>{{ state.clientServiceData?.domainUsed }}</strong> / {{ state.clientServiceData?.domainLimit }}</span>

      </div>
    </div>

    <!-- on trial info -->
    <div *ngIf="state.onTrial">

      <span class="__trigger-title">
        {{ state.translations?.trialUntil }}
      </span>

      <span class="__trigger-value  is-trial">
        {{ state.clientServiceData?.trialEnds | translocoDate: { dateStyle: 'medium', timeStyle: 'medium' } }}
      </span>

    </div>

    <!-- not on trial, show traffic -->
    <div *ngIf="!state.onTrial">
      <div class="__progress-value-wrap">

        <span class="__trigger-title">{{ state.translations?.traffic }} <span *ngIf="!!state.tariff?.priceListParam?.limitTo">{{ today | translocoDate: { month: 'long' } }}</span></span>

        <mat-progress-bar
          [color]="'accent'"
          [class.is-unlimited]="!state.tariff?.priceListParam?.limitTo"
          class="__progress"
          [mode]="'determinate'"
          [value]="!state.tariff?.priceListParam?.limitTo
            ? 0
            : (state.clientServiceAggregatedStatistics?.traffic / state.tariff?.priceListParam?.limitTo) * 100">
        </mat-progress-bar>

        <span class="__trigger-value" *ngIf="!!state.tariff?.priceListParam?.limitTo">{{ state.clientServiceAggregatedStatistics?.traffic | formatBytes }} / {{ state.tariff?.priceListParam?.limitTo * state.tariff?.priceListParam?.multiplierToBaseUnit | formatBytes }}</span>

        <span class="__trigger-value  is-unlimited" *ngIf="!state.tariff?.priceListParam?.limitTo">{{ state.translations?.unlimited }}</span>

      </div>
    </div>

    <div>
      <mat-icon
        class="__more"
        [class.is-active]="popRef.isOpen()">expand_more</mat-icon>
    </div>

  </div>

</button>

<sat-popover
  #popRef
  [horizontalAlign]="'center'"
  [verticalAlign]="'below'"
  [hasBackdrop]="true">
  <mat-card class="__pop-card  mat-mdc-elevation-specific  mat-elevation-z24" [class.is-wide]="state.activeLang === 'hu'">

    <div class="__info-item">

      <h4 class="c-info-item_label">{{ state.translations?.status }}</h4>

      <div class="c-info-item_value">
        <vshc-status-icon
          [color]="clientServiceStatusColorMap[state.clientServiceData?.status]"
          [text]="state.generalTranslations?.enums[state.clientServiceData?.status]">
        </vshc-status-icon>
      </div>

    </div>

    <div class="__info-item">

      <h4 class="c-info-item_label">{{ state.translations?.servicePackageCreated }}</h4>

      <span class="c-info-item_value">
        {{ state.clientServiceData?.created | translocoDate: { dateStyle: 'medium', timeStyle: 'medium' } }}
      </span>

    </div>

    <div class="__info-item" *ngIf="state.clientServiceData?.allowedLocations?.length">

      <h4 class="c-info-item_label">{{ state.translations?.allowedLocations }}</h4>

      <span class="c-info-item_value  is-list" *ngFor="let item of state.clientServiceData.allowedLocations; let last = last;">
        {{ item.name }}<span *ngIf="!last">,&nbsp;</span>
      </span>

    </div>

    <div class="__info-item" *ngIf="!state.onTrial">

      <h4 class="c-info-item_label">{{ state.translations?.pricingTitle }}</h4>

      <span class="c-info-item_value  u-whitespace">
        {{ state.translations?.basicLimit }}
        <strong>
          {{ state.tariff?.priceListParam?.limitTo }}
          {{ state.tariff?.priceListParam?.unitId }}
        </strong>
        {{ state.generalTranslations?.for }}
        <strong>
          {{ state.tariff?.priceListParams.regularPrice | number }}
          {{ state.tariff?.priceListParams?.currencyId }}
        </strong> / {{ state.generalTranslations?.month }}
      </span>

      <span class="c-info-item_value  u-whitespace">
        {{ state.translations?.overLimit }}
        <strong>
          {{ state.tariff?.priceListParam?.incrementSize }}
          {{ state.tariff?.priceListParam?.unitId }}
        </strong>
        {{ state.generalTranslations?.for }}
        <strong>
          {{ state.tariff?.priceListParam.pricePerIncrement | number }}
          {{ state.tariff?.priceListParams?.currencyId }}
        </strong> / {{ state.generalTranslations?.month }}
        <span class="__tariff-over-min" *ngIf="state.tariff?.priceListParam?.minimumAmount > 0">
          <br/>({{ state.translations?.minimalVolume }}
          {{ state.tariff?.priceListParam?.minimumAmount }}
          {{ state.tariff?.priceListParam?.unitId }})
        </span>
      </span>

    </div>

    <div class="__info-actions">

      <div class="__info-action-item">
        <button
          mat-flat-button
          color="primary"
          class="__action-button  c-button--larger"
          (click)="popRef.close()"
          [zefNgrxDialogOpen]="addDomainDialogKey"
          type="button">
          <mat-icon>add</mat-icon>&nbsp;<span>{{ state.translations?.addDomain }}</span>
        </button>
      </div>

      <div class="__info-action-item" *ngIf="state.onTrial">
        <zef-progress-error full [key]="terminateTrialActionKey">
          <button
            mat-button
            (click)="onTerminalTrial$.next()"
            color="warn"
            class="__action-button"
            type="button">
            {{ state.translations?.terminateTrial }}
          </button>
        </zef-progress-error>
      </div>

    </div>

  </mat-card>
</sat-popover>
