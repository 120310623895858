import { Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslocoService } from '@jsverse/transloco';

import {
  ZefReactiveComponentBase,
  selectZefDialogState,
  zefDialogClose
} from '@zerops/zef';
import { ZefProgressErrorModule } from '@zerops/zef';
import { CdnDomain } from '@vshosting/models';
import { VshcDialogWrapperComponent } from '@vshosting/components';
import { DomainEntity } from '@vshosting/cdn/core';
import { DomainInfoComponent } from '@vshosting/cdn/components';
import { FEATURE_NAME } from './domain-existence-check-dialog.constant';

@Component({
    selector: 'vshcdn-domain-existence-check-dialog',
    templateUrl: './domain-existence-check-dialog.feature.html',
    styleUrls: ['./domain-existence-check-dialog.feature.scss'],
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        ZefProgressErrorModule,
        DomainInfoComponent,
        VshcDialogWrapperComponent
    ]
})
export class DomainExistenceCheckDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #domainEntity = inject(DomainEntity);
  #translocoService = inject(TranslocoService);

  // # Event streams
  onClose$ = new Subject<void>();
  onRenewDomain$ = new Subject<void>();

  // # Data
  // -- sync
  updateActionKey = this.#domainEntity.updateOne.type;

  // -- async
  state$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.state$.pipe(map((d) => d?.state));
  meta$ = this.state$.pipe(map((d) => d?.meta as { data: CdnDomain; id: string; } ));
  translations$ = this.meta$.pipe(
    filter((d) => !!d),
    switchMap((d) => this.#translocoService.selectTranslateObject(
      'domainExistenceCheck',
      { desc: {proxyDomain: d?.data.proxyDomain }}
      ))
  );
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');
  domainInfoTranslations$ = this.#translocoService.selectTranslateObject('domainInfo');

  // # Resolver
  state = this.$connect({
    open: this.open$,
    meta: this.meta$,
    domainInfoTranslations: this.domainInfoTranslations$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$
  });

  // # Action streams
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  #renewDomainAction$ = this.onRenewDomain$.pipe(
    withLatestFrom(this.meta$),
    map(([ _, { data, id } ]) => this.#domainEntity.updateOne(
      id,
      {
        backendDomain: data.domainName,
        geoIpMode: data.geoIpMode,
        remoteLogType: data.remoteLogType,
        remoteLogDestination: data.remoteLogDestination,
        geoIpCountries: data.geoIpCountries,
        checkBackendCert: data.checkBackendCertificate,
        webp: data.webp,
        forceCache: data.forceCache,
        forceCacheTime: data.forceCacheTime,
        location: data.locations.reduce((acc, cur) => {
          acc = acc + cur.id;
          return acc;
        }, 0)
      },
      {
        tag: FEATURE_NAME
      }
    ))
  );

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this.#closeAction$,
      this.#renewDomainAction$
    ]);
  }

}
